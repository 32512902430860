import React, { useState, useEffect } from 'react';
import axios from 'axios';

const data = { 'a': '𝐚', 'b': '𝐛', 'c': '𝐜', 'd': '𝐝', 'e': '𝐞', 'f': '𝐟', 'g': '𝐠', 'h': '𝐡', 'i': '𝐢', 'j': '𝐣', 'k': '𝐤', 'l': '𝐥', 'm': '𝐦', 'n': '𝐧', 'o': '𝐨', 'p': '𝐩', 'q': '𝐪', 'r': '𝐫', 's': '𝐬', 't': '𝐭', 'u': '𝐮', 'v': '𝐯', 'w': '𝐰', 'x': '𝐱', 'y': '𝐲', 'z': '𝐳', 'A': '𝐀', 'B': '𝐁', 'C': '𝐂', 'D': '𝐃', 'E': '𝐄', 'F': '𝐅', 'G': '𝐆', 'H': '𝐇', 'I': '𝐈', 'J': '𝐉', 'K': '𝐊', 'L': '𝐋', 'M': '𝐌', 'N': '𝐍', 'O': '𝐎', 'P': '𝐏', 'Q': '𝐐', 'R': '𝐑', 'S': '𝐒', 'T': '𝐓', 'U': '𝐔', 'V': '𝐕', 'W': '𝐖', 'X': '𝐗', 'Y': '𝐘', 'Z': '𝐙', '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒', '5': '𝟓', '6': '𝟔', '7': '𝟕', '8': '𝟖', '9': '𝟗' };
const back_data = { '𝐚': 'a', '𝐛': 'b', '𝐜': 'c', '𝐝': 'd', '𝐞': 'e', '𝐟': 'f', '𝐠': 'g', '𝐡': 'h', '𝐢': 'i', '𝐣': 'j', '𝐤': 'k', '𝐥': 'l', '𝐦': 'm', '𝐧': 'n', '𝐨': 'o', '𝐩': 'p', '𝐪': 'q', '𝐫': 'r', '𝐬': 's', '𝐭': 't', '𝐮': 'u', '𝐯': 'v', '𝐰': 'w', '𝐱': 'x', '𝐲': 'y', '𝐳': 'z', '𝐀': 'A', '𝐁': 'B', '𝐂': 'C', '𝐃': 'D', '𝐄': 'E', '𝐅': 'F', '𝐆': 'G', '𝐇': 'H', '𝐈': 'I', '𝐉': 'J', '𝐊': 'K', '𝐋': 'L', '𝐌': 'M', '𝐍': 'N', '𝐎': 'O', '𝐏': 'P', '𝐐': 'Q', '𝐑': 'R', '𝐒': 'S', '𝐓': 'T', '𝐔': 'U', '𝐕': 'V', '𝐖': 'W', '𝐗': 'X', '𝐘': 'Y', '𝐙': 'Z', '𝟎': '0', '𝟏': '1', '𝟐': '2', '𝟑': '3', '𝟒': '4', '𝟓': '5', '𝟔': '6', '𝟕': '7', '𝟖': '8', '𝟗': '9' };

const emoji_data = [
    ['💎', 'Gem Stone: Represents luxury, wealth, or something precious.'],
    ['🥇', 'Gold Medal: Represents first place or victory in a competition.'],
    ['🥈', 'Silver Medal: Represents second place or commendable achievement.'],
    ['🥉', 'Bronze Medal: Represents third place or participation in a competition.'],
    ['🔗', 'Link: Represents a hyperlink or connection.'],
    ['📌', 'Pushpin: Used to indicate something important or to "pin" a note.'],
    ['🌐', 'Globe with Meridians: Represents the Earth or global connectivity.'],
    ['⏳', 'Hourglass Not Done: Indicates the passage of time or that time is running out.'],
    ['⌚', 'Watch: Represents timekeeping or the concept of time.'],
    ['🏷️', 'Label: Represents tagging or categorizing something.'],
    ['⛽', 'Fuel Pump: Represents fuel or gas stations.'],
    ['📜', 'Scroll: Represents a document or historical text.'],
    ['❤️', 'Red Heart'],
    ['🙏', 'Folded Hands']
    // Add more emojis as needed  
];

const phrase_data = [
    ['Hi\n', ' '],
    ['Hello\n', ' '],
    ['Your requirement is clear!\n', ' '],
    ['Good Job!\n', ' '],
    ['Nice idea!\n', ' '],
    ['Solana ', ' '],
    ['Ethereum ', ' '],
    ['Tron ', ' '],
    ['Bundle ', ' '],
    ['Jito ', ' '],
    ['Thank you for watching my proposal.\n', ' '],
    ['Thank you.\n', ' '],
];

const Proposals = ({ title, open, setOpen , userInfo}) => {

    const [proposal, setProposal] = useState("");
    const [hoveredEmoji, setHoveredEmoji] = useState("");
    const [hoveredPhrase, setHoveredPhrase] = useState("");
    const [copied, setCopied] = useState(false);

    const handleClear = () => {
        setProposal("");
    }
    const handleCloseDialog = () => {
        setProposal("");
        setOpen(false);
    };

    const handleCopy = () => {
        const textArea = document.createElement('textarea');
        textArea.value = proposal;
        document.body.appendChild(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
            setCopied(true);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }

        document.body.removeChild(textArea);
    };

    const handleChange = (e) => {
        setProposal(e.target.value);
    }

    const handleSave = () => {
    }

    const handleConvertAll = (e) => {
        let str = proposal;
        let newStr = '';

        const textarea = document.getElementById('proposalTextarea');
        const selectionStart = 0;
        const selectionEnd = str.length;

        for (let j = 0; j < str.length; j++) {
            if (j >= selectionStart && j < selectionEnd && data[str[j]]) {
                newStr += data[str[j]]; // Build the new string
            } else
                newStr += str[j]; // Build the new string
        }

        setProposal(newStr);
    };

    const handleConvert = (e) => {
        let str = proposal;
        let newStr = '';

        const textarea = document.getElementById('proposalTextarea');
        const selectionStart = textarea.selectionStart;
        const selectionEnd = textarea.selectionEnd;

        for (let j = 0; j < str.length; j++) {
            if (j >= selectionStart && j < selectionEnd && data[str[j]]) {
                newStr += data[str[j]]; // Build the new string
            } else
                newStr += str[j]; // Build the new string
        }

        setProposal(newStr);
    };

    const addCharacter = (newChar) => {
        const textarea = document.getElementById('proposalTextarea');
        const selectionStart = textarea.selectionStart;

        let newStr = proposal.slice(0, selectionStart) + newChar + proposal.slice(selectionStart);
        setProposal(newStr);

        setTimeout(() => {
            const newCursorPosition = selectionStart + newChar.length;
            textarea.focus();
            textarea.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
    }

    const formatPhrase = (newStr) => {
        let target = 10;
        if (newStr.length < target)
            return newStr;

        return newStr.slice(0, target) + "...";
    }

    return (
        <div>
            {open && (
                <div style={{
                    width: '100vw', height: '100vh', background: 'rgba(255,255,255,0.5)', position: 'fixed',
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    zIndex: 5000, rowGap: '10px', margin: 'auto',
                }}
                >
                    <div style={{ fontSize: '80px', fontFamily: 'cursive', color: 'black' }}>Win the bid</div>
                    <div style={{ width: '100%', marginBottom: '20px' }}>
                        <input type="text" placeholder='https://www.upwork.com/jobs/~'
                            style={{
                                width: '1060px', borderRadius: '10px',
                                height: '60px',
                                padding: '10px',
                                fontSize: '24px'
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
                        <div style={{
                            width: '60px',
                            height: '450px',
                            padding: '15px',
                            fontSize: '24px',
                            border: '1px solid gray !important',
                            borderRadius: '10px',
                            background: 'rgba(0,0,255,0.2)',
                            cursor: 'pointer',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {emoji_data.map((emoji) => (
                                <div key={emoji} style={{
                                    fontSize: '14px', padding: '5px',
                                    background: emoji == hoveredEmoji ? 'red' : 'transparent',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: 'auto',
                                    // overflow : 'auto'
                                    overflowY: 'scroll', // Enable vertical scrolling
                                    scrollbarWidth: 'none', // Hide scrollbar for Firefox
                                    msOverflowStyle: 'none', // Hide scrollbar for IE/Edge
                                    userSelect: 'none',  // Add this line to prevent text selection
                                    cursor: 'pointer'    // Optional: changes the cursor to a pointer on 
                                }}
                                    onMouseEnter={() => setHoveredEmoji(emoji)} // Set hovered emoji on mouse enter
                                    onMouseLeave={() => setHoveredEmoji(null)} // Clear hovered emoji on mouse leave
                                    onClick={() => {
                                        addCharacter(emoji[0]);
                                    }}
                                >
                                    {emoji[0]}
                                </div>
                            ))}
                        </div>
                        <textarea
                            id="proposalTextarea"
                            type="input"
                            placeholder="New Proposal..."
                            style={{
                                width: '800px',
                                height: '450px',
                                padding: '15px',
                                fontSize: '24px',
                                border: '1px solid gray !important',
                                borderRadius: '10px',
                            }}
                            onChange={handleChange}
                            value={proposal}
                            autoFocus
                        />
                        <div style={{
                            width: '160px',
                            height: '450px',
                            padding: '15px',
                            paddingLeft: '10px',
                            border: '1px solid gray !important',
                            borderRadius: '10px',
                            background: 'rgba(0,0,255,0.2)',
                            cursor: 'pointer',
                            justifyContent: 'center',
                            textAlign: 'left'
                        }}>
                            {phrase_data.map((phrase, index) => (
                                <div key={phrase} style={{
                                    fontSize: '14px', height: '30px',
                                    background: phrase == hoveredPhrase ? 'rgba(0,0,255,0.4)' : 'transparent',
                                    justifyContent: 'center',
                                    color: 'white',
                                    alignItems: 'center',
                                    fontSize: 'white',
                                    paddingLeft: '10px',
                                    userSelect: 'none',  // Add this line to prevent text selection
                                    cursor: 'pointer'    // Optional: changes the cursor to a pointer on 
                                }}
                                    readonly
                                    onMouseEnter={() => setHoveredPhrase(phrase)} // Set hovered emoji on mouse enter
                                    onMouseLeave={() => setHoveredPhrase(null)} // Clear hovered emoji on mouse leave
                                    onClick={() => {
                                        addCharacter(phrase[0]);
                                    }}
                                >
                                    - {formatPhrase(phrase[0])}
                                </div>
                            ))}
                            <div style={{
                                color: 'white',
                                padding: '10px',
                                background: 'rgba(0,255,0,0.5)',
                                borderRadius: '20px',
                                margiTop: '15px',
                                textAlign: 'center',
                                userSelect: 'none',  // Add this line to prevent text selection
                                cursor: 'pointer'    // Optional: changes the cursor to a pointer on 
                            }}>
                                ➕ Add Phrase
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', columnGap: '20px', marginTop: '20px' }}>
                        <button
                            style={{ width: '150px', height: '40px', paddingLeft: '10px', paddingRight: '10px', letterSpacing: '2px', fontFamily: 'gothic', fontsize: '24px', background: 'rgba(0,0,0)', color: 'white', borderRadius: '100px' }}
                            onClick={handleCopy}>Copy
                        </button>
                        <button
                            style={{ width: '150px', height: '40px', paddingLeft: '10px', paddingRight: '10px', letterSpacing: '2px', fontFamily: 'gothic', fontsize: '24px', background: 'rgba(0,0,0)', color: 'white', borderRadius: '100px' }}
                            onClick={handleConvert}>Emphasize
                        </button>
                        <button
                            style={{ width: '150px', height: '40px', paddingLeft: '10px', paddingRight: '10px', letterSpacing: '2px', fontFamily: 'gothic', fontsize: '24px', background: 'rgba(0,0,0)', color: 'white', borderRadius: '100px' }}
                            onClick={handleConvertAll}>Emphasize All
                        </button>
                        <button
                            style={{ width: '150px', height: '40px', paddingLeft: '10px', paddingRight: '10px', letterSpacing: '2px', fontFamily: 'gothic', fontsize: '24px', background: 'rgba(0,0,0)', color: 'white', borderRadius: '100px' }}
                            onClick={handleSave}>Save
                        </button>
                        <button
                            style={{ width: '150px', height: '40px', paddingLeft: '10px', paddingRight: '10px', letterSpacing: '2px', fontFamily: 'gothic', fontsize: '24px', background: 'rgba(0,0,0)', color: 'yellow', borderRadius: '100px' }}
                            onClick={handleClear}>Clear
                        </button>
                        <button
                            style={{ width: '150px', height: '40px', paddingLeft: '10px', paddingRight: '10px', letterSpacing: '2px', fontFamily: 'gothic', fontsize: '24px', background: 'rgba(0,0,0)', color: 'yellow', borderRadius: '100px' }}
                            onClick={handleCloseDialog}>Cancel
                        </button>
                    </div>

                </div>
            )
            }
        </div >
    );
};

export default Proposals;