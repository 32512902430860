import React, { useState, useEffect } from 'react';
import { NavItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [hardList, setHardList] = useState([
        { userId: 'rothschild', password: 'rothschild123!', permission: 100 },
        { userId: 'pji0413', password: 'pji0413', permission: 2 },
        { userId: 'kdb1213', password: 'kdb1213', permission: 2 },
        { userId: 'torres', password: 'torres123!', permission: 2 },
    ]);
    
    useEffect(() => {
        localStorage.removeItem('upwork');
    }, [])

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {

        let i = 0;
        for (i = 0; i < hardList.length; i++) {
            if (password == hardList[i].password) {
                localStorage.setItem('upwork', password);
                navigate("/win");
            }
        }
    }

    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            background: 'black'
        }}>
            <div style={{
                // width : '100vh',
                // height : '100vh',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                padding: '1.5rem',
                borderRadius: '1.5rem',
                background: 'rgba(255,255,255,0.1)',
                color: 'white'
            }}>
                <h1 style={{ margin: '1rem', letterSpacing: '2px' }}>Password : </h1>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                    style={{
                        margin: '1rem', height: '2rem', fontSize: '1.5rem',
                        height: '40px'
                    }}
                    autoFocus
                />
                <button onClick={handleSubmit} style={{
                    margin: '0.5rem',
                    width: '5rem',
                    fontSize: '1.5rem',
                    background: 'rgba(255,0,0,0.5)',
                    color: 'white',
                    borderTopRightRadius: '1rem',
                    borderBottomRightRadius: '1rem',
                    letterSpacing: '2px',
                }}> JOIN </button>
            </div>
        </div>
    );
};

export default Landing;
